import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DomainType } from '@ptg-shared/constance/common.const';
import { ADMIN_FUND, ADMIN_SYSTEM } from '@ptg-shared/constance/value.const';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EmployerPortalModule } from '@ptg-fund-list/models/fund-list.model';
import { EMPLOYER_MODULE_KEY } from '@ptg-shared/constance/employer-portal-permission.const';

import { PermissionModel } from '../../types/models/permission.model';
import { auth0Config } from '../configs/auth0.config';

@Injectable({ providedIn: 'root' })
export class Auth0Service implements OnDestroy {
  accountActivate: any = new BehaviorSubject(null);
  idTokenClaim: any;
  fundKey: string = '';
  expireTime$: any;
  name: string = '';
  employerId = '';
  isAlreadyRetrieveMuniUserPermissionFromMuniList: boolean = false;
  isMultipleEmployers: boolean | null = null;
  readonly auth0Config = auth0Config;
  //Auth0 props
  readonly appState$ = this.auth0.appState$;
  readonly isAuthenticated$ = this.auth0.isAuthenticated$;
  readonly isLoading$ = this.auth0.isLoading$;
  readonly error$ = this.auth0.error$;
  readonly idTokenClaim$ = this.auth0.idTokenClaims$;
  readonly user$ = this.auth0.user$;
  private readonly _destroying$ = new Subject<void>();
  private _role: string = '';
  private _domainType: string = '';
  private _email: string = '';
  private _permissions?: PermissionModel[];
  isAdminPortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isEmployerPortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isMunicipalityPortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMultipleEmployerPortalUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  listAllowPermission?: string[];
  existingOid?: boolean;

  constructor(
    private auth0: AuthService
  ) {
    this.isMunicipalityPortal$.next(window?.['ngEnv']?.dev?.appKey === 'PensionMunicipality');
  }

  get isSysAdmin(): boolean {
    return this.Role === ADMIN_SYSTEM;
  }

  get Role(): string {
    return this._role ? this._role : (localStorage.getItem('role') as string);
  }

  set Role(value: string) {
    this._role = value;
  }

  get Email(): string {
    return this._email ?? (localStorage.getItem('email') as string);
  }

  set Email(value: string) {
    this._email = value;
  }

  get Permissions(): PermissionModel[] {
    return this._permissions ?? JSON.parse(atob(localStorage.getItem('prm') ?? btoa('[]')));
  }

  set Permissions(value: any) {
    this._permissions = value;
  }

  initializeAuth(): void {
    this.idTokenClaim$.pipe(filter((idTokenClaim) => !!idTokenClaim)).subscribe((idTokenClaim) => {
      this.idTokenClaim = idTokenClaim;
      localStorage.setItem('idTokenClaim', JSON.stringify(this.idTokenClaim));

      if (!localStorage.getItem('role')) {
        this._role = this.idTokenClaim[`${auth0Config.claimNamespace}role`] ?? '';
        localStorage.setItem('role', this._role);
      } else this._role = localStorage.getItem('role') as string;

      if (!this._role && this.isMunicipalityPortal$.value) {
        this.logoutToErrorPage();
        return;
      }

        if (!localStorage.getItem('email')) {
          this._email = this.idTokenClaim.email || '';
          localStorage.setItem('email', this._email);
        } else this._email = localStorage.getItem('email') as string;

        if (!localStorage.getItem('name')) {
          this.name = this.idTokenClaim.name;
          localStorage.setItem('name', this.name);
        } else this.name = localStorage.getItem('name') as string;

      if (!localStorage.getItem('employerId')) {
        this.employerId = this.idTokenClaim.employerId;
        localStorage.setItem('employerId', this.employerId);
      } else this.employerId = localStorage.getItem('employerId') ?? '';

      this.fundKey = this.idTokenClaim[`${auth0Config.claimNamespace}fundkey`] || '';

      if (!localStorage.getItem('domainType')) {
        this._domainType = this.idTokenClaim.domainType ?? '';
        localStorage.setItem('domainType', this._domainType);
      } else {
        this._domainType = localStorage.getItem('domainType') as string;
      }
      this.isMultipleEmployers = this.idTokenClaim.isMultipleEmployers ?? null;
      this.existingOid = this.idTokenClaim?.existingOid ?? null;
      this.isAdminPortal$.next(this._domainType === DomainType.AdminPortal);
      this.isEmployerPortal$.next(this._domainType === DomainType.EmployerPortal);
      this.isMultipleEmployerPortalUser$.next(this.idTokenClaim?.isMultipleEmployers === true);
      this.listAllowPermission = this.idTokenClaim[`${auth0Config.claimNamespace}permissions`]
        ? this.idTokenClaim[`${auth0Config.claimNamespace}permissions`].split(',')
        : [];
    });
  }

  getPermissionForMuniPortalUser(permission: string) {
    this._permissions = permission ? JSON.parse(permission) : [];
    let permissionForLocalStorage = btoa(JSON.stringify(this._permissions));
    localStorage.setItem('prm', permissionForLocalStorage);
    this.isAlreadyRetrieveMuniUserPermissionFromMuniList = true;
  }

  setEmployerIdForMuniPortalUser(employerId: string) {
    localStorage.setItem('employerId', employerId);
    this.employerId = employerId;
  }

  login() {
    this.auth0.loginWithRedirect();
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();

    if (this.isMunicipalityPortal$.value) {
      const newWindow = window.open(environment.sawLogoutUrl);
      if (newWindow) {
        setTimeout(() => {
          newWindow.close();

          // Logout Auth0
          this.auth0.logout({ returnTo: window.location.origin });
        }, 2000);
      }
    } else {
      this.auth0.logout({ returnTo: window.location.origin });
    }
  }

  logoutToErrorPage() {
    this.auth0.logout({ returnTo: `${window.location.origin}/not-authorized`, federated: true });
    localStorage.clear();
    sessionStorage.clear();
  }

  isLoggedIn() {
    return this.isAuthenticated$.pipe().subscribe((el) => el);
  }

  buildPermissionForPortalUserByListPermissionKey(allPermissions: EmployerPortalModule[]) {
    if (localStorage.getItem('prm')) return;
    const permissions = this.buildPermissions(allPermissions, this.listAllowPermission ?? []);
    if (this.Role === ADMIN_FUND || this.isMultipleEmployerPortalUser$?.value) {
      permissions.unshift({
        Key: EMPLOYER_MODULE_KEY.EMPLOYER_LIST,
        Child: [{ Key: EMPLOYER_MODULE_KEY.EMPLOYER_LIST, Child: [] }],
      });
    }
    this._permissions = permissions;
    let permissionForLocalStorage = btoa(JSON.stringify(this._permissions));
    localStorage.setItem('prm', permissionForLocalStorage);
  }

  private buildPermissions(allPermissions: EmployerPortalModule[], listAllowPermission: string[]): PermissionModel[] {
    return allPermissions
      .filter((module) => listAllowPermission.includes(module.moduleKey))
      .map((module: EmployerPortalModule) => {
        return {
          Key: module.moduleKey,
          Child: this.buildPermissions(module.children, listAllowPermission),
          Order: module.order,
        } as PermissionModel;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
