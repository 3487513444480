import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  debounceTime,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Observable, Subject, forkJoin, of, timer } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  getDateString,
  getValueWithoutFormat,
  isEmpty,
  isString,
  setFractionalLength,
  stringToBoolean,
} from '@ptg-shared/utils/string.util';
import { BaseComponent } from '@ptg-shared/components';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { LookupTableType, ValidationType, ValidationOperator } from '@ptg-shared/types/enums';
import {
  DateValidationValue,
  FieldData,
  FormControlDetail,
  OptionValue,
  PropValidation,
} from '@ptg-shared/types/models';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone, showBanner, toCamelCase } from '@ptg-shared/utils/common.util';
import { ENTITY_BENEFICIARIES_LIST_GUID, ENTITY_DEPENDENTS_LIST_GUID, ENTITY_ORGANIZATION_GUID, ENTITY_PERSON_GUID, STATE } from '@ptg-shared/constance';
import { checkUnique } from '@ptg-shared/validators/checkUnique.validator';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';

import { EntityDataService, EntityPropertyService } from '@ptg-entity-management/services';
import { EntityPropertyType, RelationshipOptions, StaticPropertyType } from '@ptg-entity-management/types/enums';
import { CheckIsPrimarySavedRequest, EntityInitiationPropertyValue, FixedProperty, InitiationProperty, PayeeOption, PayeeRequest } from '@ptg-entity-management/services/models';
import {
  CourtOrderType,
  FixedPropertyKey,
  LIMIT_PHONE_NUMBER,
  LIST_INPUT_ADDRESS,
  LIST_INPUT_PERSON_NAME,
  LIST_INPUT_STATUS,
  PERSON_PROPERTY_MAPPING,
  StatusCourtOrder,
  UnitedStates,
} from '@ptg-entity-management/constants';
import { EMPLOYER_FIXED_PROPERTIES } from '@ptg-employer/constance/employer.const';
import { EmployerCheckDateOfBirthRequest, EmployerCheckDisableRequest } from '@ptg-employer/models/employer.model';
import * as fromMember from '../../../member/store/reducers';
import { AddressHistoryAction, clearSetMemberEventStateAction } from '@ptg-member/store/actions';
import { clearPayeePropertiesStateAction, getPayeePropertiesAction } from '@ptg-entity-management/store/actions/entity-property.action';
import { getPayeePropertiesSelector } from '@ptg-entity-management/store/selectors/entity-property.selector';
import { isEINProperty } from '@ptg-member/helper';
import { BEGIN_DATE_GREATER_THAN_DOD_ERROR_MESSAGE, END_DATE_GREATER_THAN_DOD_ERROR_MESSAGE, SERVICE_HISTORY_PROPERTIES } from '@ptg-member/constants';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

@Component({
  selector: 'ptg-upsert-entity-data',
  templateUrl: './upsert-entity-data.component.html',
  styleUrls: ['./upsert-entity-data.component.scss'],
})
export class UpsertEntityDataComponent extends BaseComponent implements AfterViewInit, OnChanges {
  readonly EntityPropertyType = EntityPropertyType;
  readonly StaticPropertyType = StaticPropertyType;
  readonly MY_DATE = MY_DATE;
  readonly FixedPropertyKey = FixedPropertyKey;
  readonly UnitedStates = UnitedStates;

  @Input() properties!: InitiationProperty[];
  @Input() fixedProperties?: FixedProperty[];
  @Input() entityId!: string;
  @Input() data: any;
  @Input() cardInfor!: {
    id: string;
    isSummaryView: boolean;
    isList: boolean;
  };
  @Input() isSubmitting: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() isEditCard?: boolean = false;
  @Input() targetId: string = '';
  @Input() screenId?: string;
  @Input() isEffectiveData: boolean = false;
  @Input() entityComponentId!: string;
  @Input() isBvff: boolean = false;
  @Input() cardName: string = '';
  @Output() editSectionEvent = new EventEmitter();
  @Output() addNewEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  bannerType = BannerType.Hidden;
  formGroup: FormGroup = new FormGroup({});
  fixedFormGroup: FormGroup = new FormGroup({});
  erFormGroup: any = {};
  erFormRadioButton: any = {};
  controls: FormControlDetail[] = [];
  erControls: any = {};
  propertyReferences!: InitiationProperty[];
  listAllStatus: any[] = [];
  listStatusEvent: Option[] = [];
  selectedRecordId: any = {};
  message: string = '';
  currentMemberDetailUnmask: any;
  datePropValidation: PropValidation[] = [];
  dateValidationValue: DateValidationValue[] = [];
  currentInactivationDate = '';
  validatorPrevious: any = {};
  formSubmit$ = new Subject<boolean>();
  payeeId: string = '';
  spousePerson?: any;
  entityReal: string = '';
  currentRelationshipValue?: number;
  relationshipLookup: any;
  isAddNew: boolean = false;
  isMuniPortal: boolean = false;
  isRetired: boolean = false;
  DOBValue: string = '';
  constructor(
    private dialog: MatDialog,
    private entityPropertyService: EntityPropertyService,
    private entityDataService: EntityDataService,
    private memberStore: Store<fromMember.MemberState>,
    public authService: Auth0Service,
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fixedProperties && this.fixedProperties && this.fixedFormGroup) {
      this.fixedProperties.filter(p => p.customErrorMessage).forEach(p => {
        const control = this.fixedFormGroup.get(p.key);
        if (control) {
          control.setErrors({ customErrorMessage: p.customErrorMessage });
        }
      });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isMuniPortal = this.authService.isMunicipalityPortal$.value;
    this._loadPropertiesToForm(this.properties, this.data);
    if (this.dateOfDeathControl && this.lineOfDutyDeathControl) {
      this._onChangeDateOfDeathValue();
    }
    if (this.newHireExamControl && this.newHireExamDateControl) {
      this._onChangeNewHireExamValue();
    }
    if (this.inactivationDateControl) {
      this._onChangeInactivationDateValue();
    }
    if (this.serviceBeginDateControl && this.serviceEndDateControl) {
      this._onChangeServiceBeginDateValue();
      this._onChangeServiceEndDateValue();
    }
    this.onSubmit();
    this.getPayeeProperties();
    this.disabledFieldsAddressMuniPortal();
  }

  ngAfterViewInit() {
    if (this.data) {
      this._setValue(this.data);
      this.initFormCourtOrder();
    }
  }

  initFormCourtOrder() {
    const checkCourtOrderType = this.controls.find((item: FormControlDetail) => item?.configs?.fixedKey === FixedPropertyKey.CourtOrderType);
    if (checkCourtOrderType) {
      const selectedCourtOrder = checkCourtOrderType?.lstOption.find((item: any) => item.value === checkCourtOrderType?.formControl.value);
      this.payeeId = this.payeeControl.value;
      const body = {
        memberId: this.targetId,
        courtOrderType: selectedCourtOrder.code === CourtOrderType.QDRO ? 0 : 1,
        screenId: this.screenId,
        courtOrderComponentId: this.entityComponentId,
        payeeId: this.payeeId,
      };
      this.memberStore.dispatch(getPayeePropertiesAction({
        body
      }));
      const checkRelationship = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip);
      if (checkRelationship) {
        checkRelationship.isHidden = selectedCourtOrder.code === CourtOrderType.QDRO ? false : true;
        checkRelationship.isRequired = selectedCourtOrder.code === CourtOrderType.QDRO ? true : false;
        checkRelationship?.formControl.setValue('');
      }
    }

    const checkStatusCourtOrder = this.controls.find((item: FormControlDetail) => item?.configs?.fixedKey === FixedPropertyKey.StatusCourtOrder);
    if (checkStatusCourtOrder && checkStatusCourtOrder.formControl.value === StatusCourtOrder.Approved) {
      checkStatusCourtOrder.lstOption = checkStatusCourtOrder.lstOption.filter(item => item.value !== StatusCourtOrder.Pending);
      this.controls.forEach(item => {
        if (item.configs?.fixedKey === FixedPropertyKey.StatusCourtOrder || item.configs?.fixedKey === FixedPropertyKey.RejectReason) {
          item.disableRow = false;
        } else {
          item.disableRow = true;
        }
      })
    }
  }

  disabledFieldsAddressMuniPortal() {
    this.memberStore.pipe(select(fromMember.selectProfileHeaderConfig),
      takeUntil(this.unsubscribe$)).subscribe(state => {
        if (state?.success) {
          const lstStatusUserEvent: string[] = ['Status event', 'Latest Status Event'];
          this.message = 'Address cannot be changed since the Member was already retired. Please contact Fund Admin for assistance if the entered Address is incorrect';
          const statusEventElm = state.payload?.find(item => lstStatusUserEvent.includes(item.propertyName));
          const statusUser = statusEventElm?.options.find((elm: any) => elm.id === statusEventElm.value.status).name;
          const fieldPrimaryAddress = this.controls.find(elm => elm.data.entityPropertyName === "Primary Address");
          this.isRetired = statusUser === 'Retired' && this.isMuniPortal;
          if (this.isRetired && fieldPrimaryAddress) {
            showBanner.call(this, BannerType.Info, '', '', { customMessage: this.message });
            fieldPrimaryAddress?.lstChildFormControl?.forEach(el => el.formControl?.disable())
          }
        }
      });
  }

  private _setValue(data: any) {
    let value: any;
    this.controls.forEach((element) => {
      if (element.type === EntityPropertyType.Aggregation || element.type === EntityPropertyType.Calculation) {
        element.isHidden = true;
      }
      if (element?.fixedPropertyKey) {
        value = data?.find((item: any) => item.fixedPropertyKey === element.fixedPropertyKey)?.value;
        element.formControl?.setValue(value);
      } else {
        value = data?.find((item: any) => item.entityPropertyId === element.name)?.value;
        if (value?.maskedValue || value?.maskedValue === null) {
          value = value.originalValue;
        }
        if (isEmpty(value) && element.type !== EntityPropertyType.Binary) {
          return;
        }
        if (
          element.type == EntityPropertyType.Address ||
          element.type == EntityPropertyType['Person Name'] ||
          element.type == EntityPropertyType.Status
        ) {
          element.lstChildFormControl?.forEach((item: any) => {
            if (item?.key === 'country') {
              if (value[item.key]) {
                item.formControl?.setValue(value[item.key]);
              }
            } else {
              item.formControl?.setValue(
                item?.key === 'effectFrom' && value?.isClearEffectFrom ? null : value[item.key] || null,
              );
            }
          });
          if (element.type == EntityPropertyType.Address) {
            element.isActive = value?.id ? true : false;
            element.code = value?.code;
          }
        } else if (element.type == EntityPropertyType.Phone) {
          element.formControl?.setValue(this.formatPhoneNumberInput(value));
        } else if (element.type == EntityPropertyType.Binary) {
          element.formControl?.setValue(value === 'true' || value ? true : false);
        } else if (element.type === EntityPropertyType['Date Time']) {
          element.formControl?.setValue(getDateString(value));
        } else {
          if (element?.fractionalLength) {
            const fractionalLengthValue = setFractionalLength(value, element?.fractionalLength);
            element.formControl?.setValue((+value)?.toFixed(fractionalLengthValue));
          } else {
            element.formControl?.setValue(value);
          }
        }
      }
    });
    this.currentInactivationDate = this._findPropertyByFixedKey(data, EMPLOYER_FIXED_PROPERTIES.InactivationDate)
      ?.value;
  }

  formatPhoneNumberInput(formControlValue: string) {
    const cleaned = ('' + formControlValue).trim().replace(/[^0-9]/g, '');
    let match = cleaned.match(LIMIT_PHONE_NUMBER);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return formControlValue;
  }

  private _loadPropertiesToForm(properties: InitiationProperty[] = [], cardData?: any): void {
    this.controls = [...properties]
      .filter(
        (prop) =>
          prop.type !== EntityPropertyType['Entity Reference'] &&
          // Auto Generated Identifier Property should not be show in Add new Member screen,
          // but still show in Edit Member screen as a label with value
          !(
            prop.type === EntityPropertyType.Identifier &&
            stringToBoolean(prop?.configs?.autogenerated) === true &&
            this.isEditCard === false
          ),
      )
      .map((prop) => {
        const isIdentifierAutoGeneratedPropertyType =
          prop.type === EntityPropertyType.Identifier && stringToBoolean(prop?.configs?.autogenerated) === true;
        let control: any;
        if (prop.configs.fixedKey && [FixedPropertyKey.Payee, FixedPropertyKey.StatusCourtOrder].includes(prop.configs.fixedKey)) {
          prop.type = EntityPropertyType.Lookup;
        }
        if (isEINProperty(prop.entityPropertyId)) {
          (prop.type as any) = 'EIN';
        }
        if (cardData) {
          control = cardData?.find((item: any) => item.entityPropertyId === prop.entityPropertyId);
        }
        if (prop.type === EntityPropertyType.Address || prop.type === EntityPropertyType['Person Name']) {
          // Address type | Person name type
          let listInputAddressDefault = deepClone(LIST_INPUT_ADDRESS);
          if (!this.isEffectiveData || this.cardInfor?.isList) {
            listInputAddressDefault = deepClone(LIST_INPUT_ADDRESS).filter((item: any) => {
              return item?.key !== 'effectTo' && item?.key !== 'effectFrom';
            });
          }
          let defaultCountry = '';
          const childFields =
            prop.type === EntityPropertyType.Address
              ? deepClone(listInputAddressDefault)
              : deepClone(LIST_INPUT_PERSON_NAME);
          childFields.forEach((field: FieldData) => {
            field.formControl = new FormControl(null);
            field.lstOption = [];

            if (prop.options?.length) {
              if (field.type === 'select' && prop.configs?.prefixList === 'true') {
                field.lstOption = prop.options.map((x: any) => ({
                  displayValue: x.text,
                  value: x.id,
                }));
              } else if (field.key === 'state') {
                field.lstOption = prop.options
                  .filter((option: OptionValue) => option.type === LookupTableType.State)
                  .map((option: OptionValue) => ({
                    displayValue: option.text,
                    value: option.id,
                  }));
              } else if (field.key === 'country') {
                field.lstOption = prop.options
                  .filter((option: OptionValue) => option.type === LookupTableType.Country)
                  .map((option: OptionValue) => ({
                    displayValue: option.text,
                    value: option.id,
                    code: option.description,
                  }));
                defaultCountry = field.lstOption.find(item => item.code === this.UnitedStates)?.value;
                field.formControl.setValue(defaultCountry ? defaultCountry : null);
              }
            }

            if (stringToBoolean(prop.configs?.readOnly)) {
              field.formControl.disable();
            }
          });
          if (prop.type === EntityPropertyType.Address && defaultCountry && prop.configs?.required === 'false') {
            childFields?.forEach((item: FieldData) => {
              if (!['effectTo', 'street2'].includes(item.key)) {
                item.isSetRequired = true;
                item?.formControl?.addValidators(Validators.required);
                item?.formControl?.updateValueAndValidity();
              }
            });
          }
          const fromElement = childFields?.find((el) => el.key === 'effectFrom');
          const toElement = childFields?.find((el) => el.key === 'effectTo');
          fromElement?.formControl?.addValidators(this._effectiveDate(fromElement, toElement));

          return {
            name: prop.entityPropertyId,
            recordId: control?.recordId,
            label: prop.label,
            type: prop.type,
            lstChildFormControl: childFields,
            isRequired: prop.configs?.required === 'true',
            data: prop,
          } as FormControlDetail;
        } else if (prop.type === EntityPropertyType.Status) {
          // Status type | Person name type
          const childFields = deepClone(LIST_INPUT_STATUS);
          childFields.forEach((field: FieldData) => {
            field.formControl = new FormControl(null);
            field.lstOption = [];

            if (prop.options?.length && field.key === 'status') {
              field.lstOption = prop.options
                .filter((item) => this.isEdit || item.active)
                ?.map((item) => {
                  return {
                    value: item.id,
                    displayValue: item.name,
                  };
                });
              this.listAllStatus = prop.options;
            }
          });

          return {
            name: prop.entityPropertyId,
            recordId: control?.recordId,
            label: prop.label,
            type: prop.type,
            lstChildFormControl: childFields,
            data: prop,
          } as FormControlDetail;
        } else {
          // One input type
          const formControl = new FormControl(null);
          const controlDetail: FormControlDetail = {
            name: prop.entityPropertyId,
            recordId: control?.recordId,
            label: prop.label,
            type: prop.type,
            lstOption: [],
            formControl: formControl,
            isRequired: prop.configs?.required === 'true',
            maxLength: 'none',
            ...(isIdentifierAutoGeneratedPropertyType && {
              isIdentifierAutoGeneratedPropertyType: true,
            }),
            fixedPropertyKey: prop.fixedPropertyKey,
            staticPropertyType: prop.staticPropertyType
          };

          // validate by property config
          const isExistingMinVal =
            stringToBoolean(prop.configs?.inclusiveInRange) &&
            isString(prop.configs?.minInput) &&
            !isNaN(Number(prop.configs?.minInput));
          const isExistingMaxVal =
            stringToBoolean(prop.configs?.inclusiveInRange) &&
            isString(prop.configs?.maxInput) &&
            !isNaN(Number(prop.configs?.maxInput));
          const isExistingMaxLengthVal =
            stringToBoolean(prop.configs?.maximumLength) &&
            isString(prop.configs?.maxLengthInput) &&
            !isNaN(Number(prop.configs?.maxLengthInput));
          const isExistingFracLengthVal =
            stringToBoolean(prop.configs?.fractionalLength) &&
            isString(prop.configs?.fractionalLengthInput) &&
            !isNaN(Number(prop.configs?.fractionalLengthInput));

          if (isExistingMinVal) {
            controlDetail.min = prop.configs?.minInput.trim() ? prop.configs?.minInput.trim() : undefined;
          }
          if (isExistingMaxVal) {
            controlDetail.max = prop.configs?.maxInput.trim() ? prop.configs?.maxInput.trim() : undefined;
          }
          if (isExistingMaxLengthVal && !isExistingMinVal && !isExistingMaxVal) {
            controlDetail.maxLength = prop.configs?.maxLengthInput;
          }
          let propTransformedType = prop.type;
          if (isExistingFracLengthVal) {
            const fractionalLength = Number(prop.configs?.fractionalLengthInput);
            if (fractionalLength === 0) {
              propTransformedType = EntityPropertyType['Whole Number'];
            } else {
              const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${fractionalLength}})?$`);
              formControl.addValidators(Validators.pattern(rgx));
              controlDetail.fractionalLength = fractionalLength.toString();
            }
          }

          switch (prop?.type) {
            case EntityPropertyType.Currency:
              formControl.setValue(null);
              break;
            case EntityPropertyType.Decimal:
              if (!isExistingFracLengthVal) {
                propTransformedType = EntityPropertyType['Whole Number'];
              }
              break;
            case EntityPropertyType.Percentage:
              formControl.addValidators(Validators.min(0));
              controlDetail.min = 0;

              formControl.addValidators(Validators.max(100));
              controlDetail.max = 100;

              const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${2}})?$`);
              formControl.addValidators(Validators.pattern(rgx));
              controlDetail.fractionalLength = 2;
              break;
            case EntityPropertyType.Date:
              let minDate: Date | undefined = undefined;
              let maxDate: Date | undefined = undefined;

              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);

              if (prop.configs?.excludeFutureDates?.toLowerCase() == 'true') {
                currentDate.setHours(24);
                maxDate = currentDate;
              }

              if (prop.configs?.excludePastDates?.toLowerCase() == 'true') {
                currentDate.setHours(-24);
                minDate = currentDate;
              }

              if (prop.configs?.dateValidation?.toLowerCase() == 'true') {
                controlDetail.isValidation = true;
                const rules = JSON.parse(prop.configs.dateValidationExpressions);
                if (rules?.length > 0) {
                  rules.forEach((rule: any) => {
                    switch (rule.validationType) {
                      case ValidationType.Year:
                        const yearValidation = +rule.value;
                        if (yearValidation) {
                          const vaidationDate = new Date();
                          vaidationDate.setFullYear(vaidationDate.getFullYear() - yearValidation);
                          if (rule.validation === ValidationOperator.LessThan) {
                            let dateVal = new Date(vaidationDate);
                            dateVal.setHours(0);
                            if (minDate == undefined || dateVal > minDate) {
                              minDate = dateVal;
                            }
                          }

                          if (rule.validation === ValidationOperator.GreaterThan) {
                            let dateVal = new Date(vaidationDate);
                            dateVal.setHours(0);
                            if (maxDate == undefined || dateVal < maxDate) {
                              maxDate = dateVal;
                            }
                          }
                        }
                        break;
                      case ValidationType.SpecificDate:
                        const dateValidation = rule.value;
                        if (dateValidation) {
                          if (rule.validation === ValidationOperator.LessThan) {
                            const dateVal = new Date(dateValidation);
                            dateVal.setHours(0);
                            if (maxDate == undefined || dateVal < maxDate) {
                              maxDate = dateVal;
                            }
                          }

                          if (rule.validation === ValidationOperator.GreaterThan) {
                            const dateVal = new Date(dateValidation);
                            dateVal.setHours(0);
                            if (minDate == undefined || dateVal > minDate) {
                              minDate = dateVal;
                            }
                          }
                        }
                        break;
                      case ValidationType.Property:
                        this.datePropValidation.push({
                          property: prop.entityPropertyId,
                          validationProp: rule.value,
                          operator: rule.validation,
                        });
                    }
                  });
                }
              }

              this.dateValidationValue.push({
                property: prop.entityPropertyId,
                max: maxDate,
                min: minDate,
              });

              if (minDate) {
                this.setMinDate(controlDetail, minDate);
              }

              if (maxDate) {
                this.setMaxDate(controlDetail, maxDate);
              }

              // check control is inactivation date
              if (prop?.configs?.fixedKey && prop?.configs?.fixedKey === EMPLOYER_FIXED_PROPERTIES.InactivationDate) {
                maxDate = currentDate;
                controlDetail.max = new Date();
                controlDetail.maxMessage = 'Inactivation Date should not be greater than today.';
                controlDetail.formControl.addAsyncValidators(this._validateInactivationDate());
              }
              if (!prop?.configs?.fixedKey || prop?.configs?.fixedKey !== EMPLOYER_FIXED_PROPERTIES.InactivationDate) {
                this._setDateValidationMessage(controlDetail);
              }

              // Validate DateOfBirth only
              if (this.isMuniPortal && prop.entityPropertyId.toLocaleLowerCase() === PERSON_PROPERTY_MAPPING.DateOfBirth.toLocaleLowerCase()) {
                controlDetail.formControl.addAsyncValidators(this._validateDateOfBirth());
              }

              break;
            default:
              break;
          }

          // set list option for List type
          let lstOption: any[] = [];
          if (
            (prop.type === EntityPropertyType.Lookup || prop.type === EntityPropertyType.Tier) &&
            prop.options?.length
          ) {
            let isRelationship = false;
            lstOption = prop.options.map((x) => {
              const result: Option = {
                displayValue: x.text,
                value: x.id,
                extraData: {
                  ...x,
                },
              };
              if (prop.configs.fixedKey && prop.configs.fixedKey === FixedPropertyKey.CourtOrderType) {
                result.code = x.code;
              }
              if (prop.configs.fixedKey && prop.configs.fixedKey === FixedPropertyKey.StatusCourtOrder) {
                result.value = x.code;
              }
              if (x.relationshipType) {
                isRelationship = true;
              }
              return result;
            });
            if (isRelationship && (properties || [])) {
              let spouseId = prop.options.find((x: any) => x.relationshipType === RelationshipOptions.Spouse).id;
              if (control?.value) {
                this.currentRelationshipValue = prop.options.find((x: any) => x.id === control.value).relationshipType;
              }
              if (control?.value !== spouseId || !control?.value) {
                this.entityPropertyService.checkExistSpouse({ targetId: this.targetId, lookupTableId: prop.configs.lookupTable, value: spouseId })?.subscribe((result: any) => {
                  if (result) {
                    this.spousePerson = deepClone(result);
                  }
                });
              }
            }
          } else if (
            (prop.type === EntityPropertyType.Employer || prop.type === EntityPropertyType.Department) &&
            prop.options?.length
          ) {
            lstOption = prop.options
              .filter((x) => x.active)
              .map((x) => ({
                displayValue: x.text,
                value: x.id,
                valueDescription: x.description,
              }));
          }

          if (stringToBoolean(prop.configs?.readOnly)) {
            formControl.disable();
          }

          // Check exists entity property value
          if (prop.configs?.unique === 'true'
            && prop.configs.autogenerated !== 'true'
            && !(this.isBvff && prop.entityPropertyId.toLowerCase() === PERSON_PROPERTY_MAPPING.SSN.toLowerCase())
          ) {
            formControl.addAsyncValidators(
              checkApiValidator(
                this.entityPropertyService.checkExistPropertyValue,
                'value',
                undefined,
                {
                  params: {
                    entityId: prop.entityId,
                    componentId: prop.entityComponentId,
                    propertyId: prop.entityPropertyId,
                    id: control?.recordId,
                    propertyType: prop.type,
                  },
                },
                () => {
                  this.formGroup.updateValueAndValidity();
                },
              ),
            );
          }

          if (prop.staticPropertyType === StaticPropertyType.Percentage) {
            formControl.addAsyncValidators(
              checkApiValidator(
                this.entityPropertyService.checkSumOfPercentageValue,
                'value',
                undefined,
                {
                  params: {
                    targetId: this.targetId,
                    recordId: control?.recordId
                  },
                },
                () => {
                  this.formGroup.updateValueAndValidity();
                },
              ),
            );
          }
          if (prop?.staticPropertyType === StaticPropertyType.IsPrimary) {
            this.entityPropertyService.isPrimary = control?.value;
          }

          if (prop.configs.fixedKey === FixedPropertyKey.LineOfDutyDeath) {
            formControl.addValidators(this._lineOfDutyDeathValidator());
            controlDetail.customError = 'invalidLineOfDutyDeath';
          }
          if (this.isMuniPortal && prop.configs.fixedKey === FixedPropertyKey.RetireRehire) {
            formControl.disable();
          }

          if (!this.isEdit) {
            if (prop.configs.fixedKey === FixedPropertyKey.RelationShip) {
              controlDetail.isHidden = true;
            }
            if (prop.configs.fixedKey === FixedPropertyKey.Payee) {
              formControl.disable();
            }
            if (prop.configs.fixedKey === FixedPropertyKey.StatusCourtOrder) {
              formControl.setValue(StatusCourtOrder.Pending);
            }
          }
          if (prop.configs.fixedKey === FixedPropertyKey.RejectReason) {
            controlDetail.isHidden = true;
          }
          controlDetail.type = propTransformedType;
          controlDetail.lstOption = lstOption;
          controlDetail.configs = prop.configs;
          controlDetail.data = prop;
          return controlDetail;
        }
      });

    //hide property Status
    if (this.isEdit) {
      this.controls = this.controls.filter((prop) => prop.type !== EntityPropertyType.Status);
    }

    // set fixedFormGroup
    this.fixedProperties?.forEach(p => {
      const formControl = new FormControl({
        value: p.value ?? null,
        disabled: p.isDisabled,
      });
      if (p.customValidators) {
        formControl.addValidators(p.customValidators);
      }
      this.fixedFormGroup.setControl(p.key, formControl);
    });

    //validate for service begin date
    if(this.isMuniPortal){
      this.serviceBeginDateControl?.addAsyncValidators(this._validateServiceBeginDate());
    }
    this.controls.forEach((f) => {
      if (f.formControl !== undefined) {
        this.formGroup.setControl(f.name, f.formControl);
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            this.formGroup.setControl(f.name + x.name, x.formControl);
          }
        });
      }
    });
    this.propertyReferences = [...properties]
      .filter((prop) => prop.type === EntityPropertyType['Entity Reference'])
      .sort((a, b) => a.order - b.order)
      .map(item => {
        return {
          ...item,
          referenceDatas: item.referenceDatas ?? [],
          initiationValue: item.initiationValue
        }
      });

    this.propertyReferences.forEach((element: any) => {
      this.erControls[element.entityPropertyId] = [];
      this.erFormGroup[element.entityPropertyId] = new FormGroup({});
      this.erFormRadioButton[element.entityPropertyId] = new FormControl();
      this.selectedRecordId[element.entityPropertyId] = '';
    })

    if (this.isEdit && this.propertyReferences.length > 0) {
      this.propertyReferences.forEach((prop: any) => {
        prop.initiationValue = true;
        prop.referenceDatas = (this.data.filter((item: any) => item.entityId === prop.entityId && item.entityComponentId === prop.entityComponentId && item.entityPropertyId === prop.entityPropertyId) || [])
          .map((item: any) => {
            const entitySelectedId = item.entityReferenceLinkedId;
            return {
              ...item,
              value: this.getPropertyValue(item.propertyType, item.value),
              recordId: item.recordId,
              recordLinkedId: item.recordLinkedId,
              valueObj:
                item.propertyType === EntityPropertyType.Address || EntityPropertyType['Person Name']
                  ? this.getPropertyValue(item.propertyType, item.value)
                  : null,
              entitySelectedId: entitySelectedId,
              entityReferenceLinkedId: item.entityReferenceLinkedId,
              entityReferencePropertyId: item.entityReferencePropertyId,
              entityReferenceId: item.entityReferenceId,
              entityReferenceComponentId: item.entityReferenceComponentId,
            };
          });
      });
    }

    const isPrimaryControl = this.controls?.find(control => control?.staticPropertyType === StaticPropertyType.IsPrimary);
    if (!isPrimaryControl) {
      const percentageControl = this.controls?.find(control => control?.staticPropertyType === StaticPropertyType.Percentage);
      percentageControl?.formControl?.clearAsyncValidators();
    }
  }

  isPrimaryChange(value: boolean) {
    const percentageField = this.controls.find(control => control.staticPropertyType === StaticPropertyType.Percentage);
    this.entityPropertyService.isPrimary = value;

    if (percentageField?.formControl.value <= 100 && percentageField?.formControl.value >= 0) {
      if (value && percentageField?.formControl.valid) {
        percentageField?.formControl.updateValueAndValidity();
      } else {
        percentageField?.formControl.setErrors(null);
      }
    }
  }

  getPropertyValue(type: EntityPropertyType, value: any) {
    if (type === EntityPropertyType.Address || type === EntityPropertyType['Person Name']) {
      if (value && typeof value === 'object' && Object.values(value).every((item) => item === null))
        return (value = null);
      return (value = value);
    }
    return value;
  }

  setStatus(childForm: FieldData) {
    if (childForm.key === 'status') {
      this.listStatusEvent = this.listAllStatus
        .find((item) => item.id === childForm.formControl?.value)
        .events.filter((item: any) => this.isEdit || item.active)
        ?.map((item: any) => {
          return {
            value: item.id,
            displayValue: item.name,
          };
        });
    }
  }

  private _effectiveDate(fromElement: any, toElement: any) {
    return (c: AbstractControl) => {
      let fromElementCompare = fromElement?.formControl?.value ? ((typeof fromElement?.formControl?.value === 'string') ? new Date(fromElement?.formControl?.value) : fromElement?.formControl?.value) : '';
      let toElementCompare = toElement?.formControl?.value ? ((typeof toElement?.formControl?.value === 'string') ? new Date(toElement?.formControl?.value) : toElement?.formControl?.value) : '';
      return (fromElementCompare
        && toElementCompare
        && fromElementCompare >= toElementCompare) ? { errorMsg: 'System does not allow Effective From is equal OR greater than Effective To.' } : null;
    };
  }

  dateEffectiveChange(event: any, lstChildFormControl?: FieldData[]) {
    lstChildFormControl?.find((el) => el.key === 'effectFrom')?.formControl?.updateValueAndValidity();
    lstChildFormControl?.find((el) => el.key === 'effectTo')?.formControl?.updateValueAndValidity();
  }

  dateValueChange(event: any, form: any): void {
    this.DOBValue = new DatePipe('en-US').transform(event, 'yyyy-MM-dd') ?? '';
    if(this.isMuniPortal){
      this.formGroup.get(PERSON_PROPERTY_MAPPING.DateOfBirth.toLocaleLowerCase())?.addAsyncValidators(this._validateDateOfBirth());
    }
    if (this.datePropValidation) {
      //validate property rules
      const validateRules = this.datePropValidation.filter((o) => o.property === form.name);
      if (validateRules) {
        for (const element of validateRules) {
          const rule = element;
          const compareProperty = rule.validationProp;

          const compareForm = this.controls.find((o) => o.name === compareProperty);
          if (compareForm) {
            const compareControl = compareForm.formControl;
            if (compareControl) {
              const valueCompare = compareControl.value;
              if (valueCompare) {
                if (rule.operator === ValidationOperator.LessThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMinDateValidation(form.name, dateValidation);
                  this.setMinDate(form.formControl, dateValidation);
                }

                if (rule.operator === ValidationOperator.GreaterThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMaxDateValidation(form.name, dateValidation);
                  this.setMaxDate(form.formControl, dateValidation);
                }
                this._setDateValidationMessage(form.formControl);
              }
            }
          }
        }
      }

      //validate property
      const validateProps = this.datePropValidation.filter((o) => o.validationProp == form.name);

      validateProps.forEach((prop) => {
        const sourceForm = this.controls.find((o) => o.name === prop.property);
        if (sourceForm) {
          if (prop.operator === ValidationOperator.LessThan && event) {
            let valDate = new Date(event);
            valDate = this.compareMaxDateValidation(prop.property, valDate);
            this.setMaxDate(sourceForm, valDate);
          }

          if (prop.operator === ValidationOperator.GreaterThan && event) {
            let valDate = new Date(event);
            valDate = this.compareMinDateValidation(prop.property, valDate);
            this.setMinDate(sourceForm, valDate);
          }

          this._setDateValidationMessage(sourceForm);
        }
      });
    }
  }

  private compareMaxDateValidation(property: string, dateVal: Date): Date {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue.find((o) => o.property === property);
      if (val && val.max) {
        dateVal.setHours(0);
        if (val.max < dateVal) {
          dateVal = val.max;
        }
      }
    }
    return dateVal;
  }

  private compareMinDateValidation(property: string, dateVal: Date): Date {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue.find((o) => o.property === property);
      if (val && val.min) {
        dateVal.setHours(0);
        if (val.min > dateVal) {
          dateVal = val.min;
        }
      }
    }
    return dateVal;
  }

  private setMaxDate(formControlDetail: FormControlDetail, vaidationDate: Date): void {
    if (vaidationDate) {
      vaidationDate.setHours(-24);

      formControlDetail.max = vaidationDate;
    }
  }

  private setMinDate(formControlDetail: FormControlDetail, vaidationDate: Date): void {
    if (vaidationDate) {
      vaidationDate.setHours(24);
      formControlDetail.min = vaidationDate;
    }
  }

  private _setDateValidationMessage(formControlDetail: FormControlDetail): void {
    const min = formControlDetail.min as Date;
    const max = formControlDetail.max as Date;
    const current = new Date();

    min?.setHours(0, 0, 0, 0);
    max?.setHours(0, 0, 0, 0);
    current.setHours(0, 0, 0, 0);

    formControlDetail.minMessage = formControlDetail.maxMessage = 'The input does not meet the validation.';

    if (min?.getTime() === current.getTime()) {
      formControlDetail.minMessage = 'The input must be greater than today';
    }

    if (max?.getTime() === current.getTime()) {
      formControlDetail.maxMessage = 'The input must be less than today';
    }

    if (max && min && min.getTime() >= max.getTime()) {
      formControlDetail.maxMessage = formControlDetail.minMessage = 'No date satifies the validation.';
    }
  }

  checkFieldsUnique(result: EntityInitiationPropertyValue[], entityPropertyId: string) {
    // Add validation about check duplicate for unique fields
    result?.forEach((item) => {
      if (item.configs?.unique === 'true') {
        let control = this.controls.find((control) => control.name === item.entityPropertyId);
        if (control) {
          if (!this.validatorPrevious[entityPropertyId]) {
            this.validatorPrevious[entityPropertyId] = {};
          }
          if (
            this.validatorPrevious[entityPropertyId] &&
            this.validatorPrevious[entityPropertyId][item.entityPropertyId]
          ) {
            control?.formControl.removeValidators(
              this.validatorPrevious[entityPropertyId][item.entityPropertyId],
            );
          }
          let type = typeof item.value;
          if (item.value && type === 'object' && item.value.originalValue) {
            this.validatorPrevious[entityPropertyId][item.entityPropertyId] = checkUnique(
              item.value.originalValue,
              item?.label + ' is already exists.',
            );
            control?.formControl.addValidators(
              this.validatorPrevious[entityPropertyId][item.entityPropertyId],
            );
          } else if (item.value && type !== 'object') {
            this.validatorPrevious[entityPropertyId][item.entityPropertyId] = checkUnique(
              item.value,
              item?.label + ' is already exists.',
            );
            control?.formControl.addValidators(
              this.validatorPrevious[entityPropertyId][item.entityPropertyId],
            );
          }
          control?.formControl.updateValueAndValidity();
        }
      }
    });
  }

  createUniqueOtherReference(property: InitiationProperty) {
    // Create uniqueOtherReference for check duplicate all fields unique from Other Reference
    let uniqueOtherReference: { parent: string; name: string; value: any }[] = [];
    this.propertyReferences
      .filter((el: InitiationProperty) => el.entityPropertyId !== property.entityPropertyId)
      .forEach((el: InitiationProperty) => {
        el.referenceDatas
          ?.filter((item) => item.configs?.unique === 'true')
          .forEach((item) => {
            let type = typeof item.value;
            uniqueOtherReference.push({
              parent: el.entityPropertyId,
              name: item.entityPropertyId,
              value: type === 'object' && item.value != null ? item.value.originalValue : item.value,
            });
          });
      });
    return uniqueOtherReference;
  }

  onSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formGroup.markAllAsTouched();
          const arrForm = Object.keys(this.erFormGroup);
          for (const element of arrForm) {
            const form = this.erFormGroup[element];
            form.markAllAsTouched();
          }
          this.fixedFormGroup.markAllAsTouched();
        }),
        debounceTime(500),
        switchMap(() => {
          const arrayForm = Object.keys(this.erFormGroup);
          return forkJoin([
            this.formGroup.statusChanges.pipe(
              startWith(this.formGroup.status),
              filter((status) => status !== AbstractControlStatus.PENDING),
              take(1),
            ),
            ...arrayForm.map(i => {
              return this.erFormGroup[i].statusChanges.pipe(
                startWith(this.erFormGroup[i].status),
                filter((status) => status !== AbstractControlStatus.PENDING),
                take(1),
              )
            }),
            this.fixedFormGroup.statusChanges.pipe(
              startWith(this.fixedFormGroup.status),
              filter((status) => status !== AbstractControlStatus.PENDING),
              take(1),
            ),
          ])
        }
        ),
        filter((status) => status.filter(i => i === AbstractControlStatus.VALID || i === AbstractControlStatus.DISABLED).length === status.length && !this.isSubmitting),
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  private saveEntityValue(entityPropertyId: string) {
    const controls = this.erControls[entityPropertyId];
    const entityPropertyValues: EntityInitiationPropertyValue[] = [];
    const datepipe = new DatePipe('en-US');
    if (this.erFormGroup[entityPropertyId].status === AbstractControlStatus.DISABLED) {
      return entityPropertyValues;
    }
    for (const f of controls) {
      let value = f.formControl?.value;
      let valueObj: any;
      let valueMask = {
        maskedValue: '',
        originalValue: '',
      };
      switch (f.type) {
        case EntityPropertyType.Status:
        case EntityPropertyType.Address:
        case EntityPropertyType['Person Name']:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            if (childForm.type === 'date') {
              objVal[childForm.name] = childForm.formControl?.value
                ? datepipe.transform(childForm.formControl?.value, 'yyyy-MM-dd')
                : f.type === EntityPropertyType.Address
                  ? null
                  : '';
            } else {
              objVal[childForm.name] = childForm.formControl?.value;
            }
          }
          value = isHaveData ? JSON.stringify(objVal) : '';
          valueObj = value ? JSON.parse(value, (key, value) => toCamelCase(key, value)) : '';
          break;
        case EntityPropertyType.Date:
          value = value ? datepipe.transform(value, 'yyyy-MM-dd') : '';
          break;
        case EntityPropertyType.Binary:
          value = value ? true : false;
          break;
        case EntityPropertyType.Percentage:
          value = value?.toString() || '';
          value = (value?.endsWith('.') ? value?.substring(0, value.length - 1) : value) || '';
          break;
        case EntityPropertyType.Email:
          value = {
            originalValue: value,
          };
          break;
        case EntityPropertyType.SSN:
          value = {
            originalValue: value,
          };
          break;
        case EntityPropertyType.Phone:
          value = {
            originalValue: value,
          };
          break;
        default:
          value = isEmpty(value) ? '' : value?.toString();
      }

      entityPropertyValues.push({
        configs: f.data.configs,
        type: f.data.type,
        label: f.data.label,
        options: f.data.options,
        entitySelectedId: this.erFormRadioButton[entityPropertyId].value,
        entityId: f.data.entityId,
        entityComponentId: f.data.entityComponentId,
        entityPropertyId: f.data.entityPropertyId,
        value: value,
        valueObj: valueObj,
        valueMask: valueMask,
        recordId: f.recordId,
        selectedRecordId: this.selectedRecordId[entityPropertyId],
        isInitiationProperty: f.data.isInitiationProperty,
        isComputedProperty: f.data.isComputedProperty,
      } as EntityInitiationPropertyValue);
    }

    return entityPropertyValues;
  }

  private saveValue(): void {
    const array = Object.keys(this.erControls);
    for (const element of array) {
      const entityPropertyId = element;
      const result: any = this.saveEntityValue(entityPropertyId);
      let propRef = this.propertyReferences.find((item: any) => item.entityPropertyId === entityPropertyId);
      if (propRef) {
        propRef.initiationValue = false;
        propRef.referenceDatas = result;
      }
      this.checkFieldsUnique(result, entityPropertyId);
    }
    const entityPropertyValues: EntityInitiationPropertyValue[] = [];
    const datepipe = new DatePipe('en-US');
    for (const f of this.controls) {
      let value = f.formControl?.value;
      let rawValue = f.formControl?.value;
      switch (f.type) {
        case EntityPropertyType.Status:
        case EntityPropertyType.Address:
        case EntityPropertyType['Person Name']:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            if (childForm.type === 'date') {
              objVal[childForm.name] = childForm.formControl?.value
                ? datepipe.transform(childForm.formControl?.value, 'yyyy-MM-dd')
                : f.type === EntityPropertyType.Address
                  ? null
                  : '';
            } else {
              objVal[childForm.name] = childForm.formControl?.value;
            }
          }
          value = isHaveData ? JSON.stringify({ ...objVal, code: f.code ?? null }) : JSON.stringify({ code: f.code ?? null });
          break;
        case EntityPropertyType.Date:
          value = value ? datepipe.transform(value, 'yyyy-MM-dd') : null;
          break;
        case EntityPropertyType.Binary:
          value = value ? true : false;
          break;
        case EntityPropertyType.Percentage:
          value = value?.toString() || null;
          value =
            (value?.endsWith('.')
              ? value?.substring(0, value.length - 1)
              : value) || null;
          break;
        case EntityPropertyType.SSN:
        case EntityPropertyType.Phone:
          value = getValueWithoutFormat(value);
          break;
        default:
          value = value ? value?.toString() : null;
      }
      // Not add Identifier Auto Generated to body request when save (act as label not form control)
      if (!f.isIdentifierAutoGeneratedPropertyType &&
        ((!this.isEdit && value !== null && value !== undefined && value !== '') ||
          this.isEdit)) {
        entityPropertyValues.push({
          recordId: f.recordId,
          entityId: f.data.entityId,
          entityComponentId: f.data.entityComponentId,
          entityPropertyId: f.data.entityPropertyId,
          value: value,
          type: f.type,
          isComputedProperty: f.data.isComputedProperty,
          rawValue,
        } as EntityInitiationPropertyValue);
      }
    }

    for (const p of this.propertyReferences) {
      if (p.referenceDatas && p.referenceDatas.length > 0) {
        const properties = p.referenceDatas;
        const entityPropertyReferencesValues: EntityInitiationPropertyValue[] = [];
        const entitySelectedId = properties?.find((item: any) => item.entitySelectedId !== null && item.entitySelectedId !== undefined)?.entitySelectedId;
        const selectedRecordId = properties?.find((item: any) => item.selectedRecordId !== null && item.selectedRecordId !== undefined)?.selectedRecordId || properties[0]?.recordLinkedId;
        for (const f of properties) {
          let value = f.value?.originalValue || f.value?.originalValue === null || f.value?.originalValue === '' ? f.value?.originalValue : f.value;
          if (f.type === EntityPropertyType.SSN || f.type === EntityPropertyType.Phone) {
            value = value ? getValueWithoutFormat(value) : value;
          }
          if ((!this.isEdit && value !== null && value !== undefined && value !== '') || this.isEdit) {
            entityPropertyReferencesValues.push({
              entityId: f.entityId,
              recordId: f.recordId,
              entityComponentId: f.entityComponentId,
              entityPropertyId: f.entityPropertyId,
              value: value,
              isComputedProperty: f.isComputedProperty
            } as EntityInitiationPropertyValue);
          }
        }

        if (entityPropertyReferencesValues.length > 0) {
          entityPropertyValues.push({
            entityId: p.entityId,
            entityComponentId: p.entityComponentId,
            entityPropertyId: p.entityPropertyId,
            entityPropertyReferenceValue: {
              recordId: selectedRecordId,
              entityId: entitySelectedId,
              entityPropertyValues: entityPropertyReferencesValues
            }
          } as any);
        }
      }
    }
    if (this.isEffectiveData && this.data && !this.cardInfor?.isList) {
      entityPropertyValues.forEach(item => {
        if (item.type === EntityPropertyType.Address) {
          const getAddressId = this.data.find((value: any) => value.entityPropertyId === item.entityPropertyId);
          if (getAddressId?.value?.id) {
            const cloneValue = item.value ? JSON.parse(item.value) : null;
            if (cloneValue) {
              cloneValue.id = getAddressId.value.id;
              cloneValue.code = getAddressId.value.code;
              item.value = JSON.stringify(cloneValue);
            }
          }
        }
      });
    }
    const request = {
      entityId: this.entityId,
      entityPropertyValues: [...entityPropertyValues],
      fixedPropertyValues: this.setFixedPropertiesValue()
    }

    if (this.isEffectiveData && !this.cardInfor?.isList) {
      const cloneTypeAddress = entityPropertyValues.filter(item => item.type === EntityPropertyType.Address);
      if (!cloneTypeAddress.length) {
        this.updateEvent(request);
        return;
      };
      let body: any = [];
      cloneTypeAddress.forEach(item => {
        const value = item.value ? JSON.parse(item.value) : null;
        if (value && Object.keys(value).length > 1) {
          const data = {
            reCordId: item.recordId ? item.recordId : null,
            entityId: item.entityId,
            key: item.entityPropertyId,
            entityPropertyId: item.entityPropertyId,
            effectFrom: value.EffectFrom,
            effectTo: value.EffectTo || null,
            id: value?.id ? value.id : null
          };
          body.push(data);
        }
      });
      this.memberStore.dispatch(
        AddressHistoryAction.checkExitAddress({ body })
      );
      let subscription = this.memberStore
        .pipe(
          select(fromMember.selectAddressHistoryExitState),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state) => {
          if (state?.messages) {
            if (state?.messages.length) {
              state?.messages?.forEach((item: any) => {
                const checkAddressError = this.controls.find(value => value.data.entityPropertyId === item.key);
                if (checkAddressError) {
                  const form = checkAddressError.lstChildFormControl?.find(f => f.key === 'effectFrom')!;
                  form.messageError = item.message;
                  form?.formControl?.setErrors({ inValidAsync: true });
                }
              });
            } else {
              this.updateEvent(request);
            }
            this.memberStore.dispatch(
              clearSetMemberEventStateAction()
            );
            subscription.unsubscribe();
          }
        });
    } else {
      this.updateEvent(request);
    }
  }

  updateEvent(data: any) {
    if (this.isEdit) {
      if (this._checkIsCallApiConfirmRetireRehire()) {
        this._showConfirmPopupRetireRehire(data, this.retireRehireControl.value);
        return;
      }
      this.editSectionEvent.emit(data);
    } else {
      this.addNewEvent.emit(data);
    }
  }

  onClickIcon(property: any) {
    property.isMasked = property.isMasked === undefined ? false : !property.isMasked;
  }

  onCancel(): void {
    this.cancelEvent.emit();
  }

  get dateOfDeathControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.DateOfDeath);
  }

  get lineOfDutyDeathControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.LineOfDutyDeath);
  }

  get newHireExamControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.NewHireExam);
  }

  get newHireExamDateControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.NewHireExamDate);
  }

  get retireRehireControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.RetireRehire);
  }

  get courtOrderTypeControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.CourtOrderType);
  }

  get statusCourtOrderControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.StatusCourtOrder);
  }

  get payeeControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.Payee);
  }

  get inactivationDateControl(): FormControl {
    return this._getControlByFixedKey(EMPLOYER_FIXED_PROPERTIES.InactivationDate);
  }

  get inactiveControl(): FormControl {
    return this._getControlByFixedKey(EMPLOYER_FIXED_PROPERTIES.Inactive);
  }

  private _getControlByFixedKey(propertyKey: string): FormControl {
    const entityPropety = this._findPropertyByFixedKey(this.properties, propertyKey);
    return this.formGroup.get(entityPropety?.entityPropertyId ?? '') as FormControl;
  }

  private _findPropertyByFixedKey(listProperty: any[], propertyKey: string) {
    return listProperty?.find((x) => x.configs?.fixedKey === propertyKey);
  }

  private _lineOfDutyDeathValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) return null;
      if (control.value && !this.dateOfDeathControl.value) {
        return {
          invalidLineOfDutyDeath: 'Invalid Line of Duty Death.',
        };
      }

      return null;
    };
  }

  private _onChangeDateOfDeathValue() {
    this.dateOfDeathControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.lineOfDutyDeathControl?.updateValueAndValidity();
    });
  }

  private _onChangeNewHireExamValue() {
    this.newHireExamControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isNewHireExam) => {
      const propetyNewHireExamDate = this._findPropertyByFixedKey(this.properties, FixedPropertyKey.NewHireExamDate);
      const newHireExamDate = this.controls.find((x) => x.name === propetyNewHireExamDate?.entityPropertyId);
      newHireExamDate!.isHidden = !isNewHireExam;
      newHireExamDate!.isRequired = isNewHireExam;
    });
  }

  private _checkIsCallApiConfirmRetireRehire(): boolean {
    const retireRehireProperty = this._findPropertyByFixedKey(this.data, FixedPropertyKey.RetireRehire);
    if (
      this.isEdit &&
      this.targetId &&
      retireRehireProperty &&
      this.retireRehireControl &&
      !this.retireRehireControl.value &&
      this.retireRehireControl.value !== retireRehireProperty.value
    ) {
      return true;
    }
    return false;
  }

  private _showConfirmPopupRetireRehire(request: any, newRetireRehire: boolean) {
    this.entityDataService.confirmRetireRehire(this.targetId, newRetireRehire).subscribe((res) => {
      if (res?.isShowConfirmPopup) {
        const message = newRetireRehire
          ? 'This participant was included in Annual Certification(s). Do you want to remove it from Annual Certification(s)?'
          : 'This participant was not included in unposted Annual Certification(s). This participant can be added back to Annual Certification(s) where eligible and Annual Certification Status will be reset to "1 - Not Complete". Do you want to include?';
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: 'Confirmation',
            text: message,
            type: ConfirmType.ConfirmSave,
            saveButtonTitle: 'Yes',
            cancelButtonTitle: 'No',
            hideSaveAsButton: true,
            hideConfirmButton: true,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          request.isConfirmRetireRehire = !!result;
          this.editSectionEvent.emit(request);
        });
      } else {
        this.editSectionEvent.emit(request);
      }
    });
  }

  private _validateInactivationDate(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {
        return of(null);
      }
      const inactivationDateValue = new DatePipe('en-US').transform(control.value, 'yyyy-MM-dd');

      if (inactivationDateValue === null) {
        return of(null);
      }

      const body: EmployerCheckDisableRequest = {
        employerId: this.targetId ?? '',
        inactivationDate: inactivationDateValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.getMunicipalityCheckDisable(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { dateValidationError: res?.message };
                }
                if (this.inactiveControl?.value === true) {
                  return null;
                }
                if (this.currentInactivationDate) {
                  this.inactiveControl?.setValue(true);
                  return null;
                }
                const dialogRef = this.dialog.open(ConfirmPopupComponent, {
                  panelClass: 'confirm-popup',
                  data: {
                    title: 'Confirmation',
                    text: res.message,
                    type: ConfirmType.ConfirmSave,
                    saveButtonTitle: 'Yes',
                    cancelButtonTitle: 'Cancel',
                    hideSaveAsButton: true,
                    hideConfirmButton: true,
                  },
                });
                dialogRef.afterClosed().subscribe((result: any) => {
                  if (!result) {
                    control.setValue(this.currentInactivationDate);
                    return;
                  }
                  this.inactiveControl?.setValue(true);
                });
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }

  private _validateDateOfBirth(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {
        return of(null);
      }
      const dateofBirthValue = new DatePipe('en-US').transform(control.value, 'yyyy-MM-dd');
      const serviceBeginDateValue = new DatePipe('en-US').transform(this.serviceBeginDateControl?.value, 'yyyy-MM-dd');

      if (dateofBirthValue === null) {
        return of(null);
      }

      const body: EmployerCheckDateOfBirthRequest = {
        memberId: this.targetId ?? '',
        serviceBeginDate: serviceBeginDateValue ?? '',
        dateOfBirth: dateofBirthValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.getMunicipalityCheckDateOfBirth(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { errorMsg: res?.message };
                }
                if (!this.serviceBeginDateControl?.hasError('required')) {
                  this.serviceBeginDateControl?.setErrors(null);
                }
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }


  private _onChangeInactivationDateValue() {
    this.inactivationDateControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((inactivationDate) => {
      if (!this.currentInactivationDate && !inactivationDate) {
        this.inactiveControl?.setValue(false);
        return;
      }
      if (this.currentInactivationDate && this.inactivationDateControl.valid && !inactivationDate) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: 'Confirmation',
            text: 'This Municipality is inactive. Are you sure you want to reactivate this Municipality?',
            type: ConfirmType.ConfirmSave,
            saveButtonTitle: 'Yes',
            cancelButtonTitle: 'Cancel',
            hideSaveAsButton: true,
            hideConfirmButton: true,
          },
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (!result) {
            this.inactivationDateControl?.setValue(this.currentInactivationDate);
            return;
          }
          this.inactiveControl?.setValue(false);
        });
      }
    });
  }

  checkTextbox(data?: FieldData[]) {
    const checkCountry = data?.find((item: FieldData) => item.key === 'country');
    const checkCode = checkCountry?.lstOption?.find((item: any) => item.value === checkCountry?.formControl?.value)!;
    return checkCode && checkCode?.code === 'USA' ? true : false;
  }

  changeValueAddress(field: FieldData, fieldDatas?: FieldData[], data?: FormControlDetail) {
    if (field.key === 'country') {
      this.checkTextbox(fieldDatas);
    }
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  changeValueInputAddress(data: FormControlDetail) {
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  validateEffectForm(data?: FormControlDetail) {
    if (!this.isEffectiveData || data?.isRequired) return;
    const checkValueForm = data?.lstChildFormControl?.find((item: FieldData) => item?.formControl?.value);
    const effectFrom = data?.lstChildFormControl?.find((item: FieldData) => item?.key === 'effectFrom');
    if (effectFrom) {
      effectFrom.isSetRequired = checkValueForm ? true : false;
    }
  }

  validateAddressField(data?: FormControlDetail) {
    if (data?.isRequired) return;
    const checkValueForm = data?.lstChildFormControl?.find((item: FieldData) => item?.formControl?.value);
    data?.lstChildFormControl?.forEach((item: FieldData) => {
      if (!['effectTo', 'street2'].includes(item.key)) {
        item.isSetRequired = checkValueForm ? true : false;
        if (checkValueForm) {
          item?.formControl?.addValidators(Validators.required);
        } else {
          item?.formControl?.removeValidators(Validators.required);
        }
        item?.formControl?.updateValueAndValidity();
      }
    });
  }

  changeLookup(data: FormControlDetail, value: any) {
    if (data?.configs?.fixedKey === FixedPropertyKey.StatusCourtOrder) {
      const selectedStatusCourtOrder = data?.lstOption.find((item: any) => item.value === data?.formControl.value);
      const checkRejectReason = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.RejectReason);
      if (checkRejectReason) {
        const listRejectReason = [StatusCourtOrder.Terminated, StatusCourtOrder.Rejected];
        checkRejectReason.isHidden = listRejectReason.includes(selectedStatusCourtOrder.value) ? false : true;
        checkRejectReason.isRequired = listRejectReason.includes(selectedStatusCourtOrder.value) ? true : false;
        checkRejectReason?.formControl.setValue('');
        if (listRejectReason.includes(selectedStatusCourtOrder.value)) {
          checkRejectReason?.formControl.addValidators(Validators.required);
        } else {
          checkRejectReason?.formControl.removeValidators(Validators.required);
        }
        checkRejectReason?.formControl.updateValueAndValidity();
      }
    }

    if (data?.configs?.fixedKey === FixedPropertyKey.CourtOrderType) {
      const selectedCourtOrder = data?.lstOption.find((item: any) => item.value === data?.formControl.value);
      this.payeeControl?.enable();
      const body: PayeeRequest = {
        memberId: this.targetId,
        courtOrderType: selectedCourtOrder.code === CourtOrderType.QDRO ? 0 : 1,
        screenId: this.screenId,
        courtOrderComponentId: this.entityComponentId
      };
      if (this.isEdit) {
        body.payeeId = this.payeeId;
      }
      const checkPayee = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.Payee);
      if (checkPayee) {
        checkPayee.lstOption = [];
        checkPayee?.formControl.setValue('');
      }
      this.memberStore.dispatch(getPayeePropertiesAction({
        body
      }));
      const checkRelationship = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip);
      this.relationshipLookup = checkRelationship;
      if (checkRelationship) {
        checkRelationship.isHidden = selectedCourtOrder.code === CourtOrderType.QDRO ? false : true;
        checkRelationship.isRequired = selectedCourtOrder.code === CourtOrderType.QDRO ? true : false;
        checkRelationship?.formControl.setValue('');
        if (selectedCourtOrder.code === CourtOrderType.QDRO) {
          checkRelationship?.formControl.addValidators(Validators.required);
        } else {
          checkRelationship?.formControl.removeValidators(Validators.required);
        }
        checkRelationship?.formControl.updateValueAndValidity();
      }
    }

    if (data?.configs?.fixedKey === FixedPropertyKey.Payee) {
      const selectedPayee = data?.lstOption.find((item: any) => item.value === data?.formControl.value);
      const checkRelationship = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip)!;
      if (selectedPayee) {
        checkRelationship.lstOption.push({
          displayValue: selectedPayee.relationship,
          value: selectedPayee.relationshipLookupId
        });
        checkRelationship?.formControl.setValue(selectedPayee.relationshipLookupId);
      }
    }
    this.currentRelationshipValue = value?.extraData?.relationshipType;
    const customError = this.validateRelationship(value?.extraData?.relationshipType);
    if (customError)
      data.formControl.setErrors({ customError });
    else
      data.formControl.clearValidators();
  }

  validateRelationship(relationshipType: number) {
    if (relationshipType != null) {
      const isBeneficiary = this.propertyReferences.find((i: any) => i.entityComponentId === ENTITY_BENEFICIARIES_LIST_GUID);
      const isDependent = this.propertyReferences.find((i: any) => i.entityComponentId === ENTITY_DEPENDENTS_LIST_GUID);
      let isPerson = this.entityReal === ENTITY_PERSON_GUID;
      let isOrganization = this.entityReal === ENTITY_ORGANIZATION_GUID;
      if (isBeneficiary && relationshipType === RelationshipOptions.ExSpouse) {
        return 'Beneficiary cannot be an Ex-Spouse';
      }
      if (isBeneficiary || isDependent) {
        if (
          [
            RelationshipOptions.Spouse,
            RelationshipOptions.Child,
            RelationshipOptions.ExSpouse,
            RelationshipOptions.Parent,
            RelationshipOptions.Sibling,
            RelationshipOptions.DomesticPartner,
          ].includes(relationshipType) &&
          isOrganization
        ) {
          return `${isBeneficiary?.label || isDependent?.label} must be a Person`;
        } else if (RelationshipOptions.Organization === relationshipType &&
          isPerson) {
          return `${isBeneficiary?.label || isDependent?.label} must be an organization`;
        }
      }
      const listPerson = Object.values(this.selectedRecordId);
      if (RelationshipOptions.Spouse === relationshipType && this.spousePerson && this.spousePerson.personId) {
        const isExactComponent = this.propertyReferences.find((i: any) => (i.entityComponentId === this.spousePerson.componentId));
        if (isExactComponent || (!isExactComponent && this.spousePerson.personId !== listPerson[0]) || this.isAddNew) {
          return 'Only one spouse is allowed. Please change the previous spouse to ex-spouse first';
        }
      }
    }
    return null;
  }

  getPayeeProperties() {
    const checkPayee = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.Payee);
    if (checkPayee) {
      checkPayee.lstOption = [];
      checkPayee?.formControl.setValue('');
    }
    this.memberStore
      .pipe(
        select(getPayeePropertiesSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state: any) => {
        if (!state?.isLoading && state?.success && state?.payload) {
          if (checkPayee) {
            checkPayee.lstOption = state?.payload?.payeeOptions.map((item: PayeeOption) => {
              return {
                ...item,
                displayValue: item.name,
                value: item.id
              }
            });
            const checkRelationship = this.controls.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip);
            const selectedPayee = checkPayee?.lstOption.find((item: any) => item.value === checkPayee?.formControl.value);
            if (selectedPayee && checkRelationship) {
              checkRelationship.lstOption = [];
              checkRelationship?.formControl.setValue('');
              if (!state?.payload.courtOrderType) {
                checkRelationship.lstOption.push({
                  displayValue: selectedPayee.relationship,
                  value: selectedPayee.relationshipLookupId
                });
                checkRelationship?.formControl.setValue(selectedPayee.relationshipLookupId);
              }
            }
          }
        }
        this.memberStore.dispatch(clearPayeePropertiesStateAction());
      });
  }

  getListUnique() {
    return this.controls
      .filter((el) => el.configs?.unique === 'true')
      .map((item) => {
        return { name: item.name, value: item.formControl.value };
      })
  }

  getUniqueOtherReference(property: InitiationProperty) {
    return this.createUniqueOtherReference(property);
  }

  onRecordChange({ id, value }: { id: string, value: string }) {
    this.selectedRecordId[id] = value;
    this.isAddNew = false;
    this.updateValidation();
  }

  getEntityReal(entityReal: string) {
    this.entityReal = entityReal;
    this.updateValidation();
  }

  onAddNewEntityReference(addNew: boolean) {
    this.isAddNew = addNew;
    this.updateValidation();
  }

  updateValidation() {
    if (this.currentRelationshipValue != null) {
      const customError = this.validateRelationship(this.currentRelationshipValue);
      const checkRelationship = this.controls.find(item => item.data?.options[0]?.relationshipType !== null);
      checkRelationship?.formControl.markAsTouched();
      if (customError)
        checkRelationship?.formControl.setErrors({ customError });
      else {
        checkRelationship?.formControl.clearValidators();
        checkRelationship?.formControl.updateValueAndValidity();
      }
    }
  }

  setFixedPropertiesValue() {
    if (!this.fixedProperties?.length)
      return null;

    return this.fixedProperties?.map(p => {
      return {
        key: p.key,
        value: this.fixedFormGroup.get(p.key)?.value
      }
    });
  }

  onChangeDate(event: any){
    if(this.isMuniPortal && event){
      this.serviceBeginDateControl?.addAsyncValidators(this._validateServiceBeginDate());
      this.formGroup.get(PERSON_PROPERTY_MAPPING.DateOfBirth.toLocaleLowerCase())?.setErrors(null)
    }
  }

  inputFieldsBeginDate(){
    if(this.isMuniPortal && this.serviceBeginDateControl?.value && this.serviceBeginDateControl?.valid){
      this.serviceBeginDateControl?.addAsyncValidators(this._validateServiceBeginDate());
      this.serviceBeginDateControl?.updateValueAndValidity();
      this.formGroup.get(PERSON_PROPERTY_MAPPING.DateOfBirth.toLocaleLowerCase())?.setErrors(null)
    }
  }
  get serviceBeginDateControl(): FormControl {
    return this.fixedFormGroup.get(SERVICE_HISTORY_PROPERTIES.BeginDate.Key) as FormControl;
  }

  get serviceEndDateControl(): FormControl {
    return this.fixedFormGroup.get(SERVICE_HISTORY_PROPERTIES.EndDate.Key) as FormControl;
  }

  private _onChangeServiceBeginDateValue() {
    this.serviceBeginDateControl?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.serviceEndDateControl.getError('customErrorMessage') !== END_DATE_GREATER_THAN_DOD_ERROR_MESSAGE) {
        this.serviceEndDateControl.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private _onChangeServiceEndDateValue() {
    this.serviceEndDateControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.serviceBeginDateControl?.getError('customErrorMessage') !== BEGIN_DATE_GREATER_THAN_DOD_ERROR_MESSAGE) {
        this.serviceBeginDateControl?.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private _validateServiceBeginDate(): AsyncValidatorFn {
    let isValidate: boolean = false;
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if(isValidate) return of(null);
      if (!control.value || control.pristine !== false) {
        return of(null);
      }
      const beginDateValue = new DatePipe('en-US').transform(control.value, 'yyyy-MM-dd');

      if (beginDateValue === null) {
        return of(null);
      }
      const body: EmployerCheckDateOfBirthRequest = {
        memberId: this.targetId ?? '',
        dateOfBirth: this.DOBValue ?? '',
        serviceBeginDate: beginDateValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.getMunicipalityCheckDateOfBirth(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { customErrorMessage: res?.message };
                }
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
                isValidate = true;
              }),
            ),
        ),
      );
    };
  }
}
