import { ActionState } from '@ptg-shared/constance/value.const';
import {
  Address,
  AsyncFunction,
  DirectDepositPayment,
  PersonName,
} from '@ptg-shared/types/models/common.model';
import { Column } from '@ptg-shared/controls/grid';
import {
  FilterPropertyType,
  Operator,
} from '@ptg-member/constance/member-list.const';

import {
  FILE_LAYOUT_SECTION_TYPE,
  PropertyType,
  SECTION_DISPLAY_TYPE,
  SECTION_TYPE,
} from '../constance/employer.const';
import { FilterInfo } from './employer-filter.model';
import { MemberFilter } from '@ptg-member/types/models';
import { ReportCategoryType } from '@ptg-employer/constance/report.const';
import { IconConfig } from '@ptg-shared/controls/select/select.component';
import { InformationPropertyConfiguration } from './employer-information-property.model';

export interface GetListEmployerQuery {
  clientId: string;
  pageIndex: number;
  pageSize: number;
  sortNames: string;
  sortType: number;
  filters?: FilterInfo[];
  screenId?: string;
  columnSetId?: string;
}

export interface EmployerList {
  total: number;
  employers: any[];
  columns: ColumnConfig[];
  messages?: string
}

export interface SearchEmployerResponse {
  employers?: any[]; // TODO
  columns?: any[]; // TODO
}

export interface ColumnConfig {
  columnName: string;
  columnKey: string;
  type: number;
  configValue: any;
}

export interface Employer {
  id?: string;
  clientId?: string;
  code: string;
  name: string;
  disabled: boolean;
}

export interface CheckEmployerExistsRequest {
  propertyKey: string;
  propertyType: PropertyType;
  value?: any;
  employerId?: string;
}

export enum SectionType {
  EmployerInformation,
  Contacts,
  Notes,
  Documents,
  PortalUsers,
  PaymentReferences,
}
export interface OverviewConfigurations {
  overviewConfigurations: SectionConfig[];
}

export interface SectionConfig {
  id: string;
  displayName: string;
  displayType: SECTION_DISPLAY_TYPE;
  type: SECTION_TYPE;
  order: number;
}

export interface BodyReoder {
  reorderItemId: string;
  upperAdjacentId: string;
}

export interface EmployerProperty {
  name: string;
  type: string;
  isStatic: boolean;
  config: {};
  options: [];
}

export interface EmployerListProperty {
  properties: EmployerProperty[];
}
export interface EmployerInfoProperties {
  properties: EmployerInfoProperty[];
}

export interface EmployerInfoProperty {
  itemKey: string;
  key: string;
  label: string;
  type: PropertyType;
  order: number;
  configs: any;
  optionValue: any[];
  value: any;
}

export interface EmployerInfoPropertyConfig {
  itemKey: string;
  key: string;
  name: string;
  type: PropertyType;
  isDisabled?: boolean;
  isRequired: boolean;
  isNotAllowSpecialCharacter?: boolean;
  maxLength?: any;
  min?: number;
  max?: number;
  fractionalLength?: number;
  asyncFn?: AsyncFunction;
  listOption: any[];
  listChildField?: any[];
  customError?: string;
  maxDate?: Date;
  errorMaxDate?: string;
}

export interface EmployerPropertyRequest {
  Name: string;
  Type: string;
  Unique?: boolean;
  lookupTableId?: string;
  AggregationFilter?: {
    scope?: number;
    aggregationType?: number;
    filters?: MemberFilter[];
  };
}

export interface EmployerFilterInfo {
  propertyName: string;
  propertyKey: string;
  itemKey: string;
  sectionName: string;
  options?: string;
  type: FilterPropertyType;
  values: string[] | number[];
  operator: Operator;
}

export interface EmployerFilterConfig {
  name: string;
  listFilterInfo: FilterInfo[];
}

export interface EmployerFilterName {
  id?: string;
  name: string;
  listFilterInfo?: any;
}

export interface EmployerFilterInformationList {
  listFilterInfo: EmployerFilterInformation[];
}

export interface EmployerFilterInformation {
  clientId: string;
  id: string;
  listFilterInfo: FilterInfo[];
  name: string;
}

export class PropertyChildField {
  key: string;
  name: string;
  type: PropertyType;
  isDisabled?: boolean;
  isRequired?: boolean;
  maxLength?: number | string;
  listOption?: any[];
  constructor(
    key: string,
    name: string,
    type: PropertyType,
    isRequired: boolean,
    maxLength: number | string
  ) {
    this.key = key;
    this.name = name;
    this.type = type;
    this.isRequired = isRequired;
    this.maxLength = maxLength;
  }
}

export interface EmployerPropertyValue {
  propertyKey: string;
  value: string;
}

export interface EditEmployerInformationRequestDto {
  propertyValues: EmployerPropertyValue[];
}

export enum SectionDisplayType {
  None,
  Left,
  Right,
}

export enum PaymentMethod {
  Check,
  eCheck,
}

export interface GetEmployerOverviewQuery {
  id: string;
  sectionType?: SectionType;
}

export interface EmployerSection {
  type: SectionType;
  displayName: string;
  displayType: SectionDisplayType;
  order: number;
  items: any[];
  isEmptySection?: boolean;
  properties?: EmployerInfoProperty[];
}

export interface EmployerOverview {
  sections: EmployerSection[];
  employerName?: string;
}
export interface EmployerContact {
  id: string;
  clientId: string;
  employerId: string;
  name: PersonName;
  address: Address;
  phone: string;
  email: string;
  isPrimary: boolean;
}

export interface EmployerPortalUser {
  id: string;
  isInactive: boolean;
  clientId: string;
  employerId: string;
  firstName: string;
  lastName: string;
  email: string;
  systemRoleId: string;
}

export interface CheckExistResponse {
  currentExists: boolean;
  message: string;
  otherExists: boolean;
}

export interface EmployerPaymentReference {
  id: string;
  method: PaymentMethod;
  paymentContent?: DirectDepositPayment;
}

export enum AggregationScope {
  'Participant List' = 0,
}

export enum AggregationType {
  'COUNT' = 0,
}

export interface QueryFilterOption {
  sectionKey: string;
  propertyKey: string;
  propertyType: number;
}

export interface EditEmployerPropertyRequestDto {
  name: string;
  configs: { key: string; value: any }[];
}

export interface DefaultLookupTableOptions {
  countryOptions: LookupTableOption[];
  stateOptions: LookupTableOption[];
  prefixOptions: LookupTableOption[];
}
export interface LookupTableOption {
  id: string;
  description: string;
  code: string;
}

export interface EmployerListConfigurations {
  employerListConfigurations: PropertyDisplayConfiguration[];
}

export interface PropertyDisplayConfiguration {
  id?: string;
  clientKey?: string;
  propertyName?: string;
  propertyKey?: string;
  headerItemKey?: string;
  headerItemType?: string;
  sectionName?: string;
  sectionKey: string;
  columnName: string;
  orderColumn?: number;
  options: string;
  orderRow?: number;
  sortType?: number;
  columnNameDescription?: string;
  order?: number;
  optionName?: string;
  deleted?: boolean;
  isUsed?: boolean;
  isEnable?: boolean;
  columnNameMaxLength?: number;
  aggregationId?: string;
  aggregationName?: string;
  headerType?: number;
}

export interface PropertyConfig {
  propertyName: string;
  propertyKey: string;
  sectionKey: string;
  sectionName: string;
  propertyType: number;
  options: { key: string; value: string }[] | null;
}
export interface SectionMetadata {
  clientKey?: string;
  sectionKey: string;
  sectionName: string;
  options?: SectionMetadataOptions[];
}

export interface EmployerListConfigurations {
  employerListConfigurations: PropertyDisplayConfiguration[];
}

export interface ListConfig {
  listConfig: PropertyDisplayConfiguration[];
}

export interface PropertyConfigs {
  listSectionProperty: PropertyConfig[];
}

export interface SectionMetadataOptions {
  propertyName: string;
  propertyKey: string;
}

export interface Bank {
  id: string;
  routingNumber: string;
  name: string;
}

export interface EditEmployerPaymentPreferencesRequestDto {
  method: PaymentMethod;
  paymentContent?: DirectDepositPayment;
}

export class EmployerSectionActionState extends ActionState {
  sectionType?: SectionType;
}

export interface EmployerCheckSectionActionState {
  isLoading?: boolean;
  otherExists?: boolean;
  errorMsg?: string;
}

export interface PayloadCheckEmail {
  email: string;
  employerId: string
}

export enum FileTemplateType {
  Regular,
  Adjustment,
  HR,
  All,
}

export enum FileFormatType {
  Column,
  Delimited,
  FixedPosition,
}

export enum DelimiterType {
  Comma,
  Semicolon,
  Space,
  Tab,
  Other,
}

export enum PostingFieldType {
  None,
  WagePeriod,
  ParticipantKey,
}

export interface FileFormatHeaderRecord {
  numberOfRows: number;
}

export interface FileFormatDetailRecord {
  ignoreFirst: number;
  dataOfOneRecordStay: number;
}

export interface FileFormatTrailerRecord extends FileFormatHeaderRecord { }

export interface EmployerFileFormat {
  type: FileFormatType;
  delimiter: DelimiterType;
  character: string;
  treatConsecutiveDelimiterAsOne: boolean;
  headerRecord: FileFormatHeaderRecord;
  detailRecord: FileFormatDetailRecord;
  trailerRecord: FileFormatTrailerRecord;
}

export interface EmployerFileTemplate {
  id: string;
  name: string;
  type: FileTemplateType;
  disabled: boolean;
  formatConfiguration?: EmployerFileFormat | null;
}

export enum FilePropertyCategory {
  None,
  Demographic,
  Identificatory,
  Financial,
}

export enum FilePropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  Whole_Number = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  DateTime = 8,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  Lookup = 50,
  Address = 100,
  Person_Name = 101,
}

export enum PropertyConfigurationToggleType {
  Required = 'required',
  InclusiveInRange = 'inclusiveInRange',
  MaximumLength = 'maximumLength',
  FractionalLength = 'fractionalLength',
  PrefixList = 'prefixList',
  ListValues = 'listValues',
  Boolean = 'boolean',
  UsedCountryLookupTable = 'usedCountryLookupTable',
  UsedStateLookupTable = 'usedStateLookupTable',
}

export enum PropertyConfigurationToggleValueType {
  MinInput = 'minInput',
  MaxInput = 'maxInput',
  MaxLengthInput = 'maxLengthInput',
  FractionalLengthInput = 'fractionalLengthInput',
  LookupTable = 'lookupTable',
  Affirmative = 'affirmative',
  Negative = 'negative',
  CountryLookupTable = 'countryLookupTable',
  StateLookupTable = 'stateLookupTable',
}

export enum DefaultBooleanValue {
  Affirmative = 'Yes',
  Negative = 'No',
}

export enum DateFormatType {
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'YYYY-MM-DD',
}

export enum DateFormat {
  'MM/dd/yyyy',
  'dd/MM/yyyy',
  'yyyy-MM-dd',
}

export enum DateTimeFormatType {
  'MM/DD/YYYY HH:MM:SS',
  'MM/DD/YYYY hh:MM:SS AM/PM',
  'DD/MM/YYYY HH:MM:SS',
  'DD/MM/YYYY hh:MM:SS AM/PM',
  'YYYY-MM-DD HH:MM:SS',
  'YYYY-MM-DD hh:MM:SS AM/PM',
}

export enum DateTimeFormat {
  'MM/dd/yyyy hh:mm:ss',
  'MM/dd/yyyy hh:mm:ss a',
  'dd/MM/yyyy hh:mm:ss',
  'dd/MM/yyyy hh:mm:ss a',
  'yyyy-MM-dd hh:mm:ss',
  'yyyy-MM-dd hh:mm:ss a',
}

export interface FilePropertyConfiguration {
  sectionKey?: string;
  propertyKey?: string;
  toggleList: { [key: string]: any } | null;
}

export interface EmployerFileProperty {
  id: string;
  name: string;
  category: FilePropertyCategory;
  type: FilePropertyType;
  disabled: boolean;
  postingFieldType: PostingFieldType;
  helpDescription?: string;
  example?: string;
  configurations?: FilePropertyConfiguration | null;
  typeName?: string;
  categoryName?: string;
  options?: [{ key: string; value: string }];
}

export interface EmployerFilePostingField {
  participantKeyCandidates: PostingField[];
  wagePeriodCandidates: PostingField[];
}

export interface PostingField {
  propertyId: string;
  propertyName: string;
  postingFieldType: PostingFieldType;
}

export interface EmployerCheckDisableRequest {
  employerId: string;
  inactivationDate: string;
}

export interface EmployerCheckDateOfBirthRequest {
  memberId?: string;
  dateOfBirth: string;
  serviceBeginDate?: string;
}

export interface EmployerCheckDisable {
  message: string;
}

export interface GetEmployerFilePropertiesRequestDto {
  pageIndex: number;
  pageSize?: number;
  sortNames: string;
  sortType: number;
}

export interface MappingFieldProperty {
  propertyKey: string;
  propertyName: string;
  type: FilePropertyType;
  configurations: { [key: string]: any };
}

export interface MappingFieldSection {
  sectionKey: string;
  sectionName: string;
  properties: MappingFieldProperty[];
}

export interface FileLayoutData {
  templateInUsed: boolean;
  employerFileLayouts: FileLayoutConfiguration[];
}

export interface FileLayoutConfiguration {
  id?: string;
  filePropertyId: string;
  filePropertyName: string;
  filePropertyType?: string;
  categoryName?: string;
  fieldName: string;
  options?: string;
  allowOverride: boolean;
  isMappedProperty: boolean;
  row: number;
  order: number;
  section: FILE_LAYOUT_SECTION_TYPE;
  length: number | null;
  start: number | null;
  end: number | null;
  deleted?: boolean;
}

export interface FileLayoutSection {
  section: FILE_LAYOUT_SECTION_TYPE;
  sectionName: string;
  sectionData: FileLayoutConfiguration[];
  columns: Column[];
}

export interface FileFormatValidateNumberQuery {
  sectionType: FILE_LAYOUT_SECTION_TYPE;
  numberOfRow: number;
}

export interface ExportEmployerFileRequest {
  fileName: string;
  listFilter: FilterInfo[];
  pageIndex: number;
  totalPerPage: number;
  sortField?: string;
  sortType?: number;
  columnSetId?: string;
}

export interface CreateEmployersRequest {
  entityPropertyValues: EntityPropertyValue[];
}

export interface SetEmployersRequest {
  employerId: string;
  entityPropertyValues: EntityPropertyValue[];
}

export interface EntityPropertyValue {
  recordId?: string;
  entityId: string;
  entityComponentId: string;
  entityPropertyId: string;
  value: any;
}

export interface EmployerEnitityPropertiesValue {
  recordId?: string;
  entityPropertyId: string;
  value: any;
  configs: any;
}

export interface GetReportCategorySidebarRequest {
  reportType?: ReportCategoryType;
}

export interface GetReportCategorySidebarResponse {
  reportCategory: ReportCategorySidebar[];
}

export interface ReportCategorySidebar {
  id: string;
  name: string;
  reports: ReportSidebar[];
}

export interface ReportSidebar {
  id: string;
  name: string;
}

export interface PensionReportInfo {
  municipalityId: string;
  municipalityName: string;
  municipalityCode: string;
  memberEntityId: string;
  dateOfDeathEntityPropertyId: string;
  nameEntityPropertyId: string;
}

export interface EmployerColumnConfigs {
  employerColumnConfigs: EmployerColumnConfig[];
}

export interface EmployerColumnConfig {
  id: string;
  auditTrail: string;
  columnSetName: string;
  listConfigs: InformationPropertyConfiguration[];
  orderRow?: number;
  value?: string;
  valueDescription?: string;
  displayValue?: string;
  isDraft?: boolean;
  employerListConfigurations: InformationPropertyConfiguration[];
  iconConfig?: IconConfig;
  dateUpdated: string;
  isDragDrop?: boolean;
}

export interface EmployerColumnSetConfigRequest{
  columnSetName: string;
  employerListConfigurations?: InformationPropertyConfiguration[];
  isDraft?: boolean;
  auditTrail?: string;
  isDragDrop?: boolean;
}

export interface ReorderEmployerColumnSetConfigRequest{
  entityId: string;
  upperAdjacentKey: any;
  reorderColumnSetId: any;
}

export interface EmployerSystemInfo{
  id: string;
  code: string;
  name: string;
  inactive: boolean;
}

export interface CheckStripeCustomerExistsExistResponse {
  isExisted: boolean;
}

export interface RegisterCustomerStripeRequest {
  employerId: string;
  email?: string;
}

export interface RegisterCustomerStripeResponse {
  customerId: string;
}
